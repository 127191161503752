@mixin padding($val: 1rem) {
  padding: $val;
}

@mixin padding-hor($val: 1rem) {
  padding-left: $val;
  padding-right: $val;
}

@mixin padding-ver($val: 1rem) {
  padding-top: $val;
  padding-bottom: $val;
}

@mixin margin-right($val: 1rem) {
  margin-right: $val;
}

@mixin gap($val: 1rem) {
  gap: $val;
}
