[data-component="feedback-bar"] {
  padding: var(--spacing-100) var(--spacing-200);
  border-radius: var(--banner-border-radius);
  font-weight: 600;
  font-size: 14px;

  display: flex;
  align-items: flex-start;

  line-height: 2;
  gap: 10px;

  svg {
    height: 20px;
    width: 20px;
    margin-top: 3px;
  }

  &.info {
    background-color: var(--color-info-500);
    border: 1px solid var(--color-info-600);
    color: white;
  }

  &.attention {
    background-color: #F4343408;
    border: 1px solid #CB0B0B;

    svg {
      color: var(--color-warning-500);
    }
  }

  &.success {
    background-color: var(--color-success-500);
    border: 1px solid var(--color-success-600);
  }

  &.warning {
    background-color: var(--color-warning-500);
    border: 1px solid var(--color-warning-500);
  }

  &.subtle {
    background-color: var(--color-gray-50);
    border: 0;
    font-weight: normal;
  }

  ul {

    li {
      list-style-type: disc;
      font-weight: normal;
      margin-left: 13px;
    }
  }
}
