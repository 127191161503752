[data-component="Signature"] {

  .signature-details-user {

    display: flex;
    align-items: stretch;
    gap: 1rem;

    margin: 1.5rem 0;

    @media(max-width: 640px) {
      flex-direction: column;
      height: unset;

      > li {
        flex-direction: row;
      }
    }

    > li {

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: .5rem;

      &:first-of-type {
        justify-content: center;
      }

      > h3 {
        @apply font-normal text-gray-600 text-300;
      }

      > span {
        @apply font-normal text-gray-800 text-400;

        &.ssn {
          @apply font-bold text-500;
        }
      }
    }
  }

  .terms-conditions {
    > a {
      color: var(--link-color);
      text-decoration: underline;
    }
  }
}
