@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@monto/tailwindcss/default";
@import "animate.css";

@import 'variables';
@import 'base/spacing';

@import 'base/layout';
@import 'base/buttons';
@import 'base/form';
@import 'base/tabs';
@import 'base/typography';
@import 'base/tag';

@import 'components/pincode';
@import 'components/dialog';
@import 'components/guarantors-list';
@import 'components/guarantor-section';
@import 'components/invoice-list';
@import 'components/select-provider';
@import 'components/loan-section';
@import 'components/signature';
@import 'components/create-invoice-form';
@import 'components/card-offer';
@import 'components/recommendation-box';
@import 'components/onboarding-test';
@import 'components/change-loan-conditions';
@import 'components/feedback-bar';
@import 'components/plate';
@import 'components/country-picker';
@import 'components/select-companybox';
@import 'components/sentry-feedback';
@import 'components/simple-dialog';
@import 'components/toaster';
@import 'components/navigation';
@import 'components/smooth-sidebar';

@import 'pages/connect-erp-index';
@import 'pages/apply';
@import 'pages/cashflow';
@import 'pages/onboarding';
@import 'pages/lendo-thanks';
@import 'pages/smooth-app';

// html {
  // make 1 rem the equivalent of 10px
  // font-size: 62.5%;
// }

body {
  height: 100vh;
}
