[data-component="tabs"] {

  display: flex;
  flex-direction: column;

  background-color: white;
  position: relative;

  @apply text-400;

  .tabs {
    display: flex;
  }

  [role="tab"] {

    position: relative;

    color: var(--text-gray-900);
    padding: 14px 32px;

    display: flex;
    align-items: center;
    align-content: center;

    text-wrap: nowrap;

    outline: none;

    &::after {
      content: '';

      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
      height: 2px;

      background-color: transparent;
      transition: background .3s ease;
    }

    &:hover {
      color: var(--color-primary-600);
    }

    &[aria-selected="true"] {

      color: var(--color-primary-600);

      &::after {
        height: 2px;
        background-color: var(--color-primary-500);
      }
    }
  }

  @media(max-width: 640px) {

    [role="tab"] {
      padding-left: 0;
      padding-right: 12px;
      font-size: 13px;

      & + [role="tab"] {
        margin-left: 10px;
      }
    }
  }

  &.tabs-inline {

    display: inline-flex;
    flex-direction: row;

    border: 1px solid var(--color-gray-50);
    border-bottom: 0;
    border-top-right-radius: var(--border-radius-medium);
    border-top-left-radius: var(--border-radius-medium);
    border-bottom: 2px solid var(--color-gray-50);

    padding-left: 12px;

    @apply text-300;

    @media(max-width: 1024px) {
      border-radius: 0;
      display: flex;
      background-color: white;
      flex: 1;

      [role="tab"] {
        padding-left: 12px;
        padding-right: 12px;
        flex: 1;
        justify-content: center;
      }
    }
  }
}
