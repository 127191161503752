[data-component="ChangeLoanConditions"] {

  display: flex;
  flex-direction: column;

  padding: var(--spacing-300) var(--spacing-200);
  border-radius: var(--border-radius-medium);
  background-color: white;
  box-shadow: var(--box-shadow-sharp);

  gap: .5rem;

  > h3 {
    @apply text-400 leading-[95%];
    font-weight: 700;
  }

  > div > label {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .form-wrapper {
    visibility: 0;
  }

  .investment-type {

    display: flex;
    gap: 1rem;


    > label{
      font-size: 12px;
      display: flex;
      align-items: center;

      > input {
        margin-right: 3px;
        width: 15px;
        height: 15px;
      }
    }

    @media (max-width: 512px) {
      > label {
        flex: 1;
      }
    }
  }

  .buttons {
    gap: .5rem;
    display: flex;
  }
}
