[data-component="smooth-sidebar"] {

  @include padding;

  padding-right: 0;
  margin-top: 2px;
  min-width: 200px;
  margin-left: -10px;

  @media(max-width: 1024px) {
    display: none;
  }

  .smooth-sidebar-sections {

    display: flex;
    flex-direction: column;

    position: sticky;
    top: 50px;
    padding-top: 50px;
    opacity: 0;
    transition-delay: 1.5s;
    transition-duration: .3s;
    transition-property: opacity;

    &[data-visible="true"] {
      opacity: 1;
    }

    > li {

      position: relative;

      min-height: 37px;
      display: flex;

      align-items: stretch;

      &::before {
        content: '';
        width: 5px;
        background-color: #E2E6E9;
        border-radius: 4px;
        display: inline-block;
      }

      > button {
        position: absolute;
        left: 10px;
        top: 0;
        right: 0;
        bottom: 0;
        color: #024A8D;

        font-weight: 400;
        font-size: var(----font-size-200);

        display: flex;
        @include padding(.5rem);

        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;

        &:hover {
          background-color: var(--color-primary-50);
        }
      }

      &+li {
        margin-top: .5rem;
      }

      &[data-iscomplete="true"]::before {
        background-color: var(--color-primary-500);
      }

      &[data-iserror="true"]::before {
        background: var(--color-warning-500);
      }

      &[data-ishighlighted="true"]::before {
        background-color: blue;
      }

      &[data-iscurrent="true"] {

        button {
          font-weight: 600;
        }
      }

      &[data-isvisible="false"] {
        display: none;
      }
    }
  }
}
