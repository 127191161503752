[data-page="smooth-app"] {

  display: flex;
  flex-direction: column;
  height: 100%;

  [data-component="ApplicationForm"] {
    display: flex;
    flex-direction: column;
  }

  .smooth-container {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .smooth-content {
    @include padding-ver(2rem);
    @include padding-hor(1.5rem);

    padding-right: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media(max-width: 1024px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
