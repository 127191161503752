[data-component="toaster"] {

  position: fixed;
  left: 20px;
  bottom: 20px;

  width: auto;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 10px;
  z-index: 15;

  @mixin toast-style($color) {

    color: $color;

    &::after {
      background-color: $color;
    }
  }

  .toast {
    padding: var(--spacing-200);

    gap: var(--spacing-100);

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: white;
    color: white;

    display: flex;
    font-size: 13px;
    align-items: flex-start;
    width: 350px;

    border-radius: var(--border-radius-small);
    overflow: hidden;
    box-shadow: var(--drop-shadow-medium);

    transition: bottom .3s ease-out;

    display: flex;
    align-items: flex-start;

    &::after {
      content: "";
      width: 7px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }

    .toaster-type-icon {
      flex-shrink: 0;
      display: grid;
      place-content: center;

      svg {
        width: 20px;
        flex-shrink: 0;
        align-self: center;
      }
    }

    .toaster-inner-wrapper {

      gap: 10px;
      display: flex;
      flex-direction: column;

      header {
        display: flex;

        h5 {
          font-weight: bold;
        }
      }

      .toaster-message {

        color: var(--color-gray-800);

        > strong {
          font-weight: bold;
        }
      }

      footer {
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        button {

          font-size: 11px;
          border-width: 0;
          color: var(--color-gray-700);

          &.toaster-primary {
            order: 1;
            background-color: var(--color-gray-700);
            color: var(--color-white);
          }
        }
      }
    }

    // close button
    .close {
      position: absolute;
      right: var(--spacing-200);
      top: var(--spacing-200);
      height: 20px;
      width: 20px;
      color: var(--color-gray-600);
    }

    @media(max-width: 580px) {
      width: auto;
      right: 20px;

      > .toast {
        width: 100%;
      }
    }

    &.error {
      @include toast-style(#cb0b0b);
    }
    &.success {
      @include toast-style(#067406);
    }
    &.info {
      @include toast-style(#19334D);
    }
    &.warning {
      @include toast-style(#9b0808);
    }
  }
}
