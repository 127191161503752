[data-component="LoanSection"] {

  padding-top: 1.5rem;
  margin-bottom: 2rem;
  position: relative;

  >.inner-wrapper {

    padding: 2.4rem 1.5rem 1.5rem 1.5rem;
    position: relative;
    border: 1px solid var(--color-gray-50);
    border-radius: var(--border-radius-medium);

    [data-component="feedback-bar"] {
      margin-bottom: var(--spacing-300);
      margin-top: var(--spacing-300);
    }

    > header {

      position: relative;
      margin-bottom: 2rem;

      > h3 {
        font-family: "Source Serif Pro", serif;
        font-weight: 700;
        margin-bottom: 0.3em;
      }
    }

    > header > p,
    .section-intro {

      color: #2A3137;
      font-size: var(--font-size-400);
      margin-bottom: 1rem;
      max-width: var(--max-line-length);
    }

    @media(max-width: 1024px) {
      padding: 0;
      border: 0;
    }
  }

  &::before {
    content: '';

    position: absolute;
    left: -16px;
    top: 10px;
    bottom: 10px;
    bottom: unset;

    height: 0;
    opacity: 0;

    transition: height .4s ease-in;
    transition: opacity .4s ease-out;
  }

  &[data-complete="true"] {

    >.inner-wrapper {
      border-color: var(--color-primary-500);
    }

    &::before {
      height: 100%;
      width: 4px;
      background-color: var(--color-primary-500);
      border-radius: 3px;
      opacity: 1;

      @media(min-width: 1024px) {
        display: none;
      }
    }
  }

  .section-foot-note {

    font-weight: var(--font-size-300);
    font-weight: normal;
    color: var(--color-gray-400);

    max-width: var(--max-line-length);

    @media(min-width: 640px) {
      column-count: 2;
    }
  }

  .status-icon {
    position: absolute;
    right: 0;
    top: 0;

    display: flex;
    align-items: center;
    gap: 4px;

    > svg {
      width: 14px;
      height: 14px;
    }

    @media(max-width: 1024px) {
      right: 0;
    }
  }

  [data-component="feedback-bar"].loan-terms {
    flex-direction: column;

    line-height: 1.6;
    gap: 0;

    > h4 {
      text-transform: uppercase;
      font-weight: bold;
      color: var(--color-primary-700);
    }
  }

  [data-component="loan-subsection"] {
    >.inner-wrapper {

      header {

        margin-bottom: 20px;

        h4 {
          color: #024A8D;
          font-size: 18px;
          margin-bottom: 10px;
          font-weight: bold;
        }

        p {
          color: #2A3137;
        }
      }
    }

    &+[data-component="loan-subsection"] {
      margin-top: var(--spacing-300);
    }
  }

  &.signature {
    background-color: #f5f5f5;
    padding-top: 0;
    border: 1px solid var(--color-gray-100);
    border-radius: var(--border-radius-medium);
    margin-top: 1.5rem;

    >.inner-wrapper {
      border: 0;
    }

    &[data-complete="true"] {
      border-color: var(--color-primary-500);
    }

    &::before {
      display: none;
    }

    @media(max-width: 1024px) {
      padding: 0;
      border-width: 3px 0 0 0;
      padding: 2.4rem 1.5rem 1.5rem 1.5rem;
      border-radius: 0;
      margin: 0 -1.5rem;
    }
  }
}

