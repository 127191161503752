.app-container {

  display: flex;
  flex-direction: column;

  > nav {
    flex-basis: auto;
  }

  > main {
    flex: 1;
  }
}
