[data-component="navigation"] {
  border-bottom: 4px solid var(--color-primary-50);

  display: flex;
  flex-direction: row;
  align-items: stretch;

  height: 4rem;

  display: grid;
  grid-template-columns: minmax(12rem, min-content) auto auto;

  .nav-logo {
    border: 1px solid pink;
    display: flex;
    @include padding-hor();

    > a {
      display: flex;
    }
  }

  .nav-items {
    border: 1px solid goldenrod;
    flex: 1;

    display: flex;
    align-items: stretch;
  }

  .nav-user {
    border: 1px dashed green;
    display: flex;
    @include padding-hor();
  }
}
