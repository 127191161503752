@keyframes fadeInSlideScale {
  from {
    opacity: 0;
    transform: translateY(-30px) scale(0.95);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Dialog styling */
dialog[data-component="simple-dialog"] {

  display: none;
  opacity: 0;

  overflow: hidden;
  width: auto;

  border-radius: var(--border-radius-medium);
  box-shadow: var(--drop-shadow-medium);

  .dialog-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &[data-size="small"] {
    max-width: 400px;
  }

  &[data-size="medium"] {
    max-width: 550px;
  }

  &[data-size="large"] {
    max-width: 700px;
  }


  &::backdrop {
    display: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0;
  }

  // close button
  .close {
    position: absolute;
    right: var(--spacing-200);
    top: var(--spacing-200);
    height: 1em;
    width: 1em;
    color: var(--color-white);
  }

  /* When the dialog is shown, apply the fade-in animation */
  &[open] {
    display: block;
    animation: fadeInSlideScale 0.4s ease forwards;

    &::backdrop {
      animation: fadeIn 0.3s ease forwards;
      opacity: 1;
    }
  }

  &[data-size] {

    @media(max-width: 640px) {
      margin-left: 0;
      margin-right: 0;
      margin-top: 65px;
      width: 100%;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      max-width: none;

      &::backdrop {
        top: 65px;
      }

      &[open] {
        animation: fadeIn 0.4s ease forwards;
      }
    }
  }
}

