.sentry-feedback {

  header {

    display: flex;

    background-color: var(--color-primary-800);
    padding: var(--spacing-200) var(--spacing-200);
    gap: 10px;

    font-size: 14px;
    color: var(--color-white);

    > svg {
      width: 16px;
      height: 16px;
      margin-top: 3px;
    }

    h1 {
      font-weight: 600;
    }
  }

  main {

    display: flex;
    flex-direction: column;

    background-color: #FAFAFA;

    padding: var(--spacing-200) var(--spacing-200);
    gap: 1rem;

    form > .form-group {
      max-width: initial;
    }

    textarea {
      height: 100px;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;

    margin-top: 1rem;
    gap: 1rem;
  }
}

[data-component="sentry-feedback-button"] {

  position: fixed;
  top: 120px;
  right: 20px;

  display: flex;
  justify-content: flex-end;

  > button {
    background-color: var(--color-primary-800);
    color: var(--color-white);
    padding: var(--spacing-100);
    border-radius: 100%;
    width: 40px;
    height: 40px;
    min-width: initial;

    > span {
      background-color: white;
      border-radius: 100%;
      width: 16px;
      height: 16px;
      color: var(--color-primary-800);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
